import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader/SectionHeader";
import PortfolioItem from "components/PortfolioItem/PortfolioItem";
import PageSection from "components/PageSection/PageSection";
import "./Portfolio.scss";

const Portfolio = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <PortfolioItem
          imageFileName="portfolio/pvc_dograma.jpg"
          header="PVC (ПВЦ) дограма"
          linkTo="pvc-dograma"
        />
        <PortfolioItem
          imageFileName="portfolio/aluminieva_dograma.jpg"
          header="Алуминиева дограма"
          linkTo="aluminieva-dograma"
        />
        <PortfolioItem
          imageFileName="portfolio/vhodni-vrati.jpg"
          header="Блиндирани входни врати"
          linkTo="vhodni-vrati"
        />
        <PortfolioItem
          imageFileName="portfolio/vertical_blinds.jpg"
          header="Вертикални щори"
          linkTo="vertikalni-shtori"
        />
        <PortfolioItem
          imageFileName="portfolio/rolo_blinds.jpg"
          header="Роло щори"
          linkTo="rolo-shtori"
        />
        <PortfolioItem
          imageFileName="portfolio/vanshni_shtori.jpg"
          header="Външни ролетни щори"
          linkTo="roletni-shtori"
        />
        <PortfolioItem
          imageFileName="portfolio/ohranitelni_roletki.jpg"
          header="Охранителни ролетки"
          linkTo="ohranitelni-roletki"
        />
        <PortfolioItem
          imageFileName="portfolio/sectional-doors.jpg"
          header="Секционни врати"
          linkTo="sekcionni-vrati"
        />
        <PortfolioItem
          imageFileName="portfolio/interiorni-vrati.jpg"
          header="Интериорни врати"
          linkTo="interiorni-vrati"
        />
        <PortfolioItem
          imageFileName="portfolio/sunshades.jpg"
          header="Сенници и тенти"
          linkTo="sennici-tenti"
        />
      </Row>
    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Portfolio.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Portfolio;

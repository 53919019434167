import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Icon from "components/Icon";
import PageSection from "components/PageSection/PageSection";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, telephone, email, secondHeader, city, street } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={4} className="ml-auto text-center">
          <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3" />
          {(telephone && telephone.split(",")).map((tel) => (
            <a className="d-block" href={`tel:${tel}`} key={tel}>
              {tel}
            </a>
          ))}
        </Col>
        <Col lg={4} className="mr-auto text-center mt-2 mt-lg-0">
          <Icon iconName="EnvelopIcon" size="3x" className="text-muted mb-3" />
          <a className="d-block" href={`mailto:${email}`}>
            {email}
          </a>
        </Col>
        <Col lg={4} className="mr-auto text-center mt-2 mt-lg-0">
          <Icon iconName="FacebookIcon" size="3x" className="text-muted mb-3" />
          <a
            className="d-block d-lg-none"
            href="https://m.me/Бисер-Пласт-Партнерс-ЕООД-1951646928290856"
          >
            Бисер Пласт Партнерс
          </a>
          <a
            className="d-none d-lg-block"
            href="https://facebook.com/Бисер-Пласт-Партнерс-ЕООД-1951646928290856"
          >
            Бисер Пласт Партнерс
          </a>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <hr className="divider my-4" />
          <h2 className="mt-0">{secondHeader}</h2>
          <hr className="divider my-4" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <span>{city}</span>
          <div className="mb-4">{street}</div>
          <iframe
            title="address"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2898.1429770385434!2d24.60977051549014!3d43.41583937913015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ab8b52818b6c87%3A0x5193ec60d23db2d6!2z0JHQuNGB0LXRgCDQn9C70LDRgdGCINCf0LDRgNGC0L3QtdGA0YE!5e0!3m2!1sbg!2sbg!4v1617494916366!5m2!1sbg!2sbg"
            height="450"
            style={{ border: "none", width: "100%" }}
            allowFullScreen={false}
            loading="lazy"
          />
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;

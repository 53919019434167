import React from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";
import Image from "components/Image";

import "./PortfolioItem.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const PortfolioItem = ({ imageFileName, imageAlt, header, subheader, linkTo }) => {
  return (
    <>
      <Col lg={4} md={6} sm={6} className="portfolio-item">
        <Link className="portfolio-link" tabIndex={-1} to={linkTo}>
          <Image
            className="img-fluid"
            fileName={imageFileName}
            alt={imageAlt || header || subheader}
          />
          <div className="portfolio-hover">
            <div className="portfolio-hover-content">
              <FontAwesomeIcon icon={faArrowRight} size="2x" />
              {/* <Icon iconName="PlusIcon" size="2x" /> */}
            </div>
          </div>
        </Link>
        <div className="portfolio-caption">
          <Link role="button" tabIndex={-1} data-toggle="modal" to={linkTo}>
            {header}
          </Link>
          {subheader ? <p className="text-muted">{subheader}</p> : null}
        </div>
      </Col>
      {/* {showChildren ? (
        <PortfolioDetailModal show={showDetail} onHide={handleHideDetail} header={header}>
          {children}
        </PortfolioDetailModal>
      ) : (
        <PortfolioDetailDialog
          show={showDetail}
          onHide={handleHideDetail}
          imageFileName={imageFileNameDetail || imageFileName}
          imageAlt={imageAltDetail || imageAlt}
          header={header}
          subheader={subheader}
          content={content}
          extraInfo={extraInfo}
        />
      )} */}
    </>
  );
};

PortfolioItem.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  linkTo: PropTypes.string,
  // content: PropTypes.string,
  // imageFileNameDetail: PropTypes.string,
  // imageAltDetail: PropTypes.string,
  // extraInfo: PropTypes.any,
  // showChildren: PropTypes.bool,
  // children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

PortfolioItem.defaultProps = {
  imageAlt: "",
  subheader: "",
  linkTo: "",
  // content: "",
  // imageFileNameDetail: "",
  // imageAltDetail: "",
  // extraInfo: null,
  // showChildren: false,
  // children: null,
};

export default PortfolioItem;
